import { IReactionPublic } from 'mobx';

/**
 * Debounces effect
 * @param {function(*, IReactionPublic) => void} effect reaction effect
 * @param {number} ms number of milliseconds to debounce the effect
 */
function debounce(effect, ms) {
    /**
     * @type {NodeJS.Timeout}
     */
    let timer;

    return (e, r) => {
        clearTimeout(timer);
        timer = setTimeout(() => effect(e, r), ms)
    }
}

export {
    debounce
}
