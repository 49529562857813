export const urlLanguageDetector = {
    name: 'urlLanguageDetector',

    lookup(options) {
        let found;
        if (typeof window !== 'undefined') {
            const language = window.location.pathname.match(/^(\/[a-zA-Z]{2})/g)[0]; // language definition will always be first. e.g. /en/home
            found = language.replace('/', '');
        }
        return found;
    }
}