import { createAtom, IAtom, observable, action, reaction } from 'mobx';

class Clock {
    @observable tzOffset = 0;

    /**
     * @type {IAtom}
     */
    atom;

    /**
     * @type {number|null}
     */
    intervalHandler = null;

    /**
     * @type {Date}
     */
    currentDateTime;

    constructor(rootStore) {
        this.rootStore = rootStore;
        this.tzOffset = App.utils.getTimezone().offset;

        reaction(() => this.rootStore.timezonesStore.selectedTimezone, tz => {
            if (tz) {
                this.tzOffset = tz.offset;
            }
        }, {
            fireImmediately: true
        });

        this.atom = createAtom(
            'Clock',
            () => this.startTicking(),
            () => this.stopTicking()
        );
    }

    getTime() {
        if (this.atom.reportObserved()) {
            return this.currentDateTime;
        }
        else {
            return new Date();
        }
    }

    tick() {
        // this.currentDateTime = new Date();
        const now = new Date();

        this.currentDateTime = new Date(now.getTime() + this.tzOffset * 60000);

        this.atom.reportChanged();
    }

    startTicking() {
        // initial tick
        this.tick();
        this.intervalHandler = setInterval(() => this.tick(), 500);
    }

    stopTicking() {
        clearInterval(this.intervalHandler);
        this.intervalHandler = null;
    }
}

export default Clock;
