import { observable, action } from "mobx";

class CookieConsentStore {

    @observable isCookieConsentVisible = false;

    constructor() {
        this.onInitialize();
    }

    onInitialize() {
        if (App.utils.getCookie('.AspNet.Consent') == null) {
            //No aspnet.consent cookie
            this.isCookieConsentVisible = true;
        }
    }

    @action.bound
    acceptCookieConsent() {
        App.utils.setCookie('.AspNet.Consent', 'yes', 365, 'Lax');
        this.isCookieConsentVisible = false;
    }
}

export default CookieConsentStore;