import { httpClient } from '../../../utils/http';

const url = '/api/timezones';
const apiHeaders = {
    'Content-Type': 'application/json'
};

class TimezonesService {
    constructor() {
        this.fetch = this.fetch.bind(this);
        this.updateTimezone = this.updateTimezone.bind(this);
    }

    /**
     * Gets all timezones available on the server
     * @returns {Promise<{id: string, name: string, offset: number}[]>}
     */
    fetch() {
        return httpClient.get(url, null, { responseType: 'json' });
    }

    /**
  * Search the timezones by the search phrase. If the search phrase emtpy, it returns all timezones
  * 
  */
    async searchTimeZone(searchPhrase) {
        let searchUrl = '/en/TimeZone/GetTimeZonesByPhrase?searchPhrase=' + searchPhrase;

        const response = await httpClient.get(searchUrl, null, { responseType: 'json' });
        return response;
    }

    /**
     * Gets the current timezone
     * @returns {Promise<{id: string, name: string, offset: number}>}
     */
    async getCurrentTimezone() {
        return await httpClient.get(url + '/selected', null, { responseType: 'json' });
    }

    /**
     * Updates timezone
     * @param {{id: string, name: string, offset: number}} timezone Timezone object
     */
    async updateTimezone(timezone) {
        return await httpClient.post(url, JSON.stringify(timezone), apiHeaders, { responseType: 'json' });
    }
}

export default TimezonesService;