import { observable, action, runInAction } from 'mobx';

import { logger } from '../../../providers';
import { LoginHistoryService } from '../../../services/components';

class LoginHistoryViewStore {
    @observable loginHistory = {};
    @observable isLoading = true;

    constructor() {
        this.service = new LoginHistoryService();
    }

    @action.bound
    async onInitialize() {
        this.isLoading = true;

        try {
            const response = await this.service.getLoginHistory();

            runInAction(() => {
                this.loginHistory = response;
            });
        }
        catch (err) {
            logger.logError('Failed to fetch login history.', err);
        }
        finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    @action.bound
    onDispose() {
        this.loginHistory = {};
        this.isLoading = true;
    }
}

export default LoginHistoryViewStore;