class SessionStorageProvider {
    constructor() {
        this.storage = sessionStorage;
    }

    init(key, initValue) {
        const item = this.storage.getItem(key);

        if (!item) {
            this.set(key, initValue);

            return initValue;
        }

        return JSON.parse(item);
    }

    get(key) {
        const item = this.storage.getItem(key);

        if (item) {
            return JSON.parse(item);
        }
        return null;
    }

    set(key, value) {
        this.storage.setItem(key, JSON.stringify(value));
    }

    remove(key) {
        this.storage.removeItem(key);
    }

    flush() {
        this.storage.clear();
    }
}

export default new SessionStorageProvider();
