class RouteSportSegments {
    /**
     * Parses url segments to get selected sports, categories and tournaments
     * @param {string} segmentsUrl 
     * @returns {{sports:string[], categories: string[], tournaments:string[], specials: []}}
     */
    static parse(segmentsUrl) {
        let selected = {
            sports: [],
            categories: [],
            tournaments: [],
            /**
             * Array of category ids for which to take specials
             */
            specials: []
        };

        /**
         * Segments url format: /sport|category-tournament
         * Complex example: /s1|c1-t1-t2/s2|c2|c3-t3/s3|c4-t4|c5-t5-t6
         */
        if (segmentsUrl) {
            // array of sport segments in format: /s|c-t
            const sportSegments = segmentsUrl.split('/');

            sportSegments.forEach(ss => {
                // we check if sport segment has |, that means we have category below
                if (ss.indexOf('|') > -1) {
                    // first category segment after split by | will be sport, we remove it
                    let categorySegments = ss.split('|');
                    categorySegments.shift();

                    categorySegments.forEach(cs => {
                        if (cs.indexOf('-') > -1) {
                            // first tournament segment after split by - will be category, we remove it
                            const tournamentSegments = cs.split('-');
                            const category = tournamentSegments.shift();

                            // selected.tournaments = selected.tournaments.concat(tournamentSegments);
                            tournamentSegments.forEach(t => {
                                if(t === 'special') {
                                    // this is special offer, we save category for which to take special offer
                                    selected.specials.push(category);
                                }
                                else {
                                    selected.tournaments.push(t);
                                }
                            });
                            // tournamentSegments.forEach(t => {
                            //     // if collon is present, it is special offer tournament
                            //     // in format 's:categoryId'
                            //     if (t.indexOf(':') > -1) {
                            //         const categoryId = t.split(':')[1];
                            //         selected.specials.push(categoryId);
                            //     }
                            //     else {
                            //         selected.tournaments.push(t);
                            //     }
                            // });
                        }
                        else {
                            // category segment does not have tournaments below
                            // this means that entire category should be selected
                            selected.categories.push(cs);
                        }
                    });
                }
                else {
                    // sport segment does not have category below
                    // this means that entire sport should be selected
                    selected.sports.push(ss);
                }
            });
        }

        return selected;

    }
}

export default RouteSportSegments;