import { observable, action, runInAction, computed } from "mobx";
import { LanguageService } from "../../../services/preferences";
import { logger } from "../../../providers";

class LanguageStore {
	@observable isLoading = true;
	@observable languages = [];
	@observable _internalCulture = App.utils.getCurrentCulture();

	@computed get currentCulture() {
		return this.languages.find((i) => i.abrv === this._internalCulture);
	}

	constructor(rootStore) {
		this.rootStore = rootStore;

		this.service = new LanguageService();
	}

	@action.bound
	async onInitialize() {
		this.isLoading = true;

		const response = await this.service.fetch();

		runInAction(() => {
			this.languages = response;
			this.isLoading = false;
		});
	}

	/**
	 * Changes language
	 * @param {{abrv: string, name: string}} culture
	 */
	@action.bound
	async onChange(culture) {
		const path = App.state.history.location.pathname;

		const returnUrl = path != null && path !== "" ? "~" + path : "~/";

		try {
			const response = await this.service.changeLanguage(
				returnUrl,
				culture.abrv
			);

			logger.log(
				`Language changed. Redirecting to: ${response.redirectUrl}`
			);

			runInAction(() => {
				// this._internalCulture = culture.abrv;

				// redirect to url with valid new culture
				window.location.href = response.redirectUrl;
			});
		} catch (e) {
			logger.logError("Failed to change language", {
				culture: culture,
				returnUrl: returnUrl,
			});
		}
	}
}

export default LanguageStore;
