import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

/**
 * Redirect to url
 * @param {string} url url to redirect to
 * @param {boolean} replace if True, url state is replaced, otherwise push
 */
export function redirect(url, replace = false) {
    if (replace) {
        history.replace(url);
    }
    else {
        history.push(url);
    }
};

export function redirectPreserveSearchParams(url, replace = false) {
    const currentSearchParams = App.state.history.location.search
    if (replace) {
        history.replace(url + currentSearchParams);
    } else {
        history.push(url + currentSearchParams);
    }
}

/**
 * 
 * @param {object} params to be added to url, object is mapped as key value pairs. 
 * @param {boolean} replace if True, url state is replaced, otherwise push
 */
export function updateUrlParams(params = {}, replace = false) {
    const newUrlParams = new URLSearchParams("");

    /** Add new query keys to url. */
    for (const [key, value] of Object.entries(params)) {
        newUrlParams.set(key, value);
    }

    /** Get new query parameters for url. */
    const newQueryParams = newUrlParams.toString() === '' ? '' : '?' + newUrlParams.toString();
    if (replace) {
        if (newQueryParams !== history.location.search) {
            /** If it should only replace url, do only when parameters have changed. */
            redirect(history.location.pathname + newQueryParams, replace);
        }
    } else {
        redirect(history.location.pathname + newQueryParams);
    }
}

/**
 * @returns {object} object which has properties set to search string variables and there values
 */
export function getUrlParamsAsObj(){
    const currentSearchString = App.state.history.location.search;
    const currentUrlParams = new URLSearchParams(currentSearchString);
    const result = {};
    for(const [key, value] of Array.from(currentUrlParams.entries())) {
        result[key] = value;
    }

    return result;
}

export default history;