import moment from 'moment';


const periods = {
    'full': {
        time: () => moment().endOf('day').add(200, 'years'),
    },
    '3d': {
        time: () => moment().endOf('day').add(3, 'days'),
    },
    'today': {
        time: () => moment().endOf('day'),
    },
    '48h': {
        time: () => moment().add(48, 'hours'),
    },
    '24h': {
        time: () => moment().add(24, 'hours'),
    },
    '3h': {
        time: () => moment().add(3, 'hours'),
    }
}

const validPeriods = Object.keys(periods);

class PeriodConverter {

    /**
     * Calculates offer interval based on period
     * @param {'today'|'3h'|'24h'|'3d'|'full'} period 
     * @returns {Date} Js date
     */
    static CalculateOfferInterval(period) {

        const validPeriod = PeriodConverter.IsValidPeriod(period) ? period : PeriodConverter.GetDefaultPeriod();
        const momentTime = periods[validPeriod].time();

        // If there are any seconds or minutes in current hour then round up to the next hour.
        if (momentTime.second() !== 0 || momentTime.minute() !== 0) {
            momentTime.startOf('hour').add(1, 'hours');
        }

        return momentTime.utc().toDate();
    }

    /**
     * Calculates offer interval based on period
     * @param {string} period 
     * @returns {boolean} True if period is valid, false otherwise
     */
    static IsValidPeriod(period) {
        return validPeriods.includes(period);
    }


    /**
     * Returns default period
     * @returns {string} period
     */
    static GetDefaultPeriod() {
        return 'full';
    }
}

export default PeriodConverter;