import i18n from 'i18next';
import Backend from 'i18next-fetch-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { urlLanguageDetector } from './urlLanguageDetector';

const languageDetector = new LanguageDetector();
languageDetector.addDetector(urlLanguageDetector);

i18n
    .use(Backend)
    .use(languageDetector)
    .use(initReactI18next)
    .init({
        ns: ['translation', 'specific'],
        defaultNS: 'translation',
        fallbackLng: 'en',
        debug: false,
        load: 'currentOnly',
        react: {
            wait: true,
            bindI18n: false,
            bindStore: false
        },
        preload: ['en'],
        backend: {
            requestOptions: {
                cache: 'no-cache'
            },
            loadPath: function (lng, ns) {
                if (ns.indexOf('translation') === -1) {
                    ns = 'translation.' + ns;
                }
                return '/assets/locales/{{lng}}/' + ns + '.json';
            }
        },
        detection: {
            lookupCookie: 'lng',
            caches: ['cookie'],
            order: ['urlLanguageDetector']
        }
    });

export default i18n;