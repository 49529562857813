import { observable, action, computed } from 'mobx';
import moment from 'moment';
import { TimezonesService } from '../../../services/preferences';
import BaseTimezoneStore from './TimezoneStore.js';

class TimezoneStore extends BaseTimezoneStore {
    @observable isOpen = false;
    @observable internalSelectedTimezone;

    constructor(rootStore) {
        super(rootStore)
        this.service = new TimezonesService();
        this.internalSelectedTimezone = this.selectedTimezone;
    }

    @computed get utcNumber() {
        if (this.selectedTimezone) {
            return moment().utcOffset(this.selectedTimezone.offset).format('Z');
        }
        else {
            return moment().local().format('Z');
        }
    }

    @action.bound
    onOpen() {
        this.isOpen = true;
    }

    @action.bound
    onClose() {
        this.isOpen = false;
    }

    @action.bound
    toggleTimeZonePopup() {
        this.isOpen = !this.isOpen;
    }

    @action.bound
    selectTimezone(tz) {
        if (this.internalSelectedTimezone !== tz) {
            this.internalSelectedTimezone = tz;
        }
    }

    @action.bound
    async setSelectedTimezoneBtn() {
        this.selectedTimezone = this.internalSelectedTimezone;
        await this.service.updateTimezone(this.selectedTimezone);
        this.isOpen = false;
    }
}

export default TimezoneStore;