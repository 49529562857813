import { action, observable, computed, runInAction } from "mobx";

const cameraPermissionStatus = {
    GRANTED: 'AccessGranted',
    ACCESS_DENIED: 'NotAllowedError',
    DEVICE_NOT_FOUND: 'NotFoundError',
    USED_BY_ANOTHER_APP: 'NotReadableError',
    NONE: 'None'
}

class QRCodeScannerStore{
    @observable hasCameraPermission = cameraPermissionStatus.NONE;
    @observable cameraConstraints = null;
    @observable numberOfRender = 1;
    @observable isMobileApp;
    @computed get cameraPermissionStatus() {
        return cameraPermissionStatus;
    }  

    constructor(rootStore) {
        this.rootStore = rootStore;
    } 

    @action.bound
    onQRCodeScan = (result) => {
        if(this.hasCameraPermission != cameraPermissionStatus.GRANTED) {
            this.setCameraPermission(cameraPermissionStatus.GRANTED);
        }
        if(this.isValidUrl(result)) {
            if(result) {
                this.redirectToBetSlipUrl(result);
            }
        }
    }

    @action.bound
    setCameraPermission(permissionStatus){
        this.hasCameraPermission = permissionStatus;
    }

    isMobileAppCheck() {
        const userAgent = navigator?.userAgent;
        this.isMobileApp = userAgent.includes(AndroidAppUserAgent);
    }

    @action.bound 
    async initializeCameraConstraints() {
        var isDeviceFound = false;        
        if(navigator.mediaDevices?.enumerateDevices) {
            var devices = await navigator.mediaDevices.enumerateDevices();
            
            devices.forEach((device) => {
                if(device.kind == "videoinput" && device.label.includes("camera2 0")) {
                    this.cameraConstraints = {deviceId: device.deviceId};
                    isDeviceFound = true;
                }
            })        
        }
        if(!isDeviceFound) {
            this.cameraConstraints = {facingMode: "environment"}
        }

        this.cameraConstraints.zoom = 2.5;
    }

    @action.bound 
    handleOnLoad() {
        if(this.numberOfRender == 1 && this.cameraConstraints.deviceId == undefined) {
            runInAction(async() => {
                await this.initializeCameraConstraints();
                this.numberOfRender++;
            })
        }
    }

    isValidUrl(urlString) {
        if(urlString != null) {
            var baseUrl = location.host;  
            var qrCodeBaseUrl = urlString.split('/').slice(2)[0]
            
            if(baseUrl === qrCodeBaseUrl){
                return true;
            }
        }
        return false;
    }

    @action.bound
    handleError(err) {
        if(err.name == this.cameraPermissionStatus.ACCESS_DENIED){
            this.setCameraPermission(this.cameraPermissionStatus.ACCESS_DENIED);
        }
        else if(err.name == this.cameraPermissionStatus.USED_BY_ANOTHER_APP){
            this.setCameraPermission(this.cameraPermissionStatus.USED_BY_ANOTHER_APP);
        }
        else {
            this.setCameraPermission(this.cameraPermissionStatus.DEVICE_NOT_FOUND);
        }
    }

    redirectToBetSlipUrl(currentUrl){
        window.location.href = currentUrl;
    }
}

export default QRCodeScannerStore;