const httpClient = App.utils.httpClient;

class LoginHistoryService {
    async getLoginHistory() {
        const url = '/api/login-history';

        return await httpClient.get(url, null, { responseType: 'json' });
    }
}

export default LoginHistoryService;