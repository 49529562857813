import { observable, action } from "mobx";

class AppPopupStore {
    @observable isAppPopupVisible = false;

    constructor() {
        this.onInitialize();
    }

    onInitialize() {
        if (!sessionStorage.getItem('appPopup') && !App.utils.getCookie('appPopup') && !this.isMobileAppCheck()) {
            this.isAppPopupVisible = true;
        }
    }

    @action.bound
    hidePopupForSession() {
        sessionStorage.setItem('appPopup', true);
        this.isAppPopupVisible = false;
    }

    @action.bound
    dontShowAgain() {
        App.utils.setCookie('appPopup', 'true', 365);
        this.isAppPopupVisible = false;
    }

    @action.bound
    isMobileAppCheck() {
        const userAgent = navigator?.userAgent;
        return userAgent.includes(AndroidAppUserAgent);
    }
}

export default AppPopupStore;