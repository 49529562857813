class ConsoleLogger {
    constructor(config = {
        isEnabled: false,
        logLevel: LogLevel.TRACE
    }) {
        this.isEnabled = config.isEnabled;

        this.logLevel = config.logLevel

        this.log = this.log.bind(this);
        this.logTrace = this.logTrace.bind(this);
        this.logInfo = this.logInfo.bind(this);
        this.logWarn = this.logWarn.bind(this);
        this.logError = this.logError.bind(this);
    }

    logTrace(message, data) {
        if (this.isEnabled && this.logLevel >= LogLevel.TRACE) {
            console.log(...compileMessage('TRACE', message, data));
        }
    }

    logInfo(message, data) {
        if (this.isEnabled && this.logLevel >= LogLevel.INFO) {
            console.info(...compileMessage('INFO', message, data));
        }
    }

    logWarn(message, data) {
        if (this.isEnabled && this.logLevel >= LogLevel.WARN) {
            console.warn(...compileMessage('WARNING', message, data));
        }
    }

    logError(message, data) {
        if (this.isEnabled && this.logLevel >= LogLevel.ERROR) {
            console.error(...compileMessage('ERROR', message, data));
        }
    }

    log(message, data) {
        if (this.isEnabled) {
            if (this.logLevel >= LogLevel.ERROR) {
                this.logError(message, data);
            }
            else if (this.logLevel >= LogLevel.WARN) {
                this.logWarn(message, data);
            }
            else if (this.logLevel >= LogLevel.INFO) {
                this.logInfo(message, data);
            }
            else if (this.logLevel >= LogLevel.TRACE) {
                this.logTrace(message, data);
            }
        }
    }
}

const LogLevel = {
    TRACE: 4,
    INFO: 3,
    WARN: 2,
    ERROR: 1
}

function compileMessage(type, message, data) {
    var params = [`${type}:`, message];
    if (data !== undefined) {
        params = params.concat([', | Data:', data]);
    }

    return params;
}

const consoleLogger = new ConsoleLogger({
    isEnabled: IsLoggingEnabled,
    logLevel: LogLevel
});
export { LogLevel }
export default consoleLogger;