import { observable, action, runInAction } from 'mobx';

import { TimezonesService } from '../../../services/preferences';

class TimezoneStore {
    @observable isLoading = true;
    @observable timezones = [];

    @observable selectedTimezone = null;

    constructor(rootStore) {
        this.rootStore = rootStore;

        this.service = new TimezonesService();

        const tzStr = App.utils.getCookie('tz');

        if (tzStr != null && tzStr != '') {
            const tzStrSplit = tzStr.split('|');

            this.selectedTimezone = {
                id: tzStrSplit[0],
                offset: +tzStrSplit[1],
            }
        }
    }

    @action.bound
    async onInitialize() {
        this.isLoading = true;
        const availableTimezones = await this.service.fetch();

        await this.getCurrentTimezone();

        runInAction(() => {
            this.timezones = availableTimezones;
            this.isLoading = false;
        });
    }

    @action.bound
    async getCurrentTimezone() {
        const currentTimezone = await this.service.getCurrentTimezone();
        runInAction(() => {
            this.selectedTimezone = currentTimezone;
        });
    }

    @action.bound
    async setSelectedTimezone(tz) {
        if (this.selectedTimezone !== tz) {
            this.selectedTimezone = tz;

            await this.service.updateTimezone(tz);
        }
    }
}

export default TimezoneStore;