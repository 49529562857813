import { httpClient } from '../../../utils/http';

const url = '/api/languages';

class LanguageService {
    constructor() {
        this.fetch = this.fetch.bind(this);
        this.changeLanguage = this.changeLanguage.bind(this);
    }

    /**
     * Gets all available languages from the server
     * @returns {Promise<{abrv: string, name: string}[]>}
     */
    async fetch() {
        return await httpClient.get(url, null, { responseType: 'json' });
    }

    /**
     * Changes the language
     * @param {string} returnUrl where to return after language change
     * @param {string} culture new culture
     * @returns {Promise<{redirectUrl: string, returnUrl: string, culture: string}>}
     */
    async changeLanguage(returnUrl, culture) {
        const model = {
            returnUrl: returnUrl,
            culture: culture
        };

        return await httpClient.post(url + '/change', JSON.stringify(model), {
            'Content-Type': 'application/json'
        }, { responseType: 'json' })
    }
}

export default LanguageService;