import BaseClock from './BaseClock.js';
import { autorun } from 'mobx';

class Clock extends BaseClock {

    constructor(rootStore) {
        super(rootStore);

        autorun(() => {
            const time = this.getTime();

            const hh = time.getUTCHours();
            const mm = time.getUTCMinutes();
            const ss = time.getUTCSeconds();

            let timeStr = `${(hh < 10 ? "0" : "")}${hh}:${mm < 10 ? "0" : ""}${mm}:${ss < 10 ? "0" : ""}${ss}`;

            let offsetStr;
            if (this.tzOffset > 0) {
                offsetStr = `+${this.tzOffset / 60}`
            }
            else if (this.tzOffset < 0) {
                offsetStr = `${this.tzOffset / 60}`
            }

            if (offsetStr) {
                timeStr += ` GMT${offsetStr}`
            }

            if (document.querySelector('#clock') != null) {
                document.querySelector('#clock').innerHTML = timeStr;
            }
        })
    }
}

export default Clock;
