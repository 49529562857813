import { observable, action, autorun } from 'mobx';

// const theme = process.env.npm_config_theme || 'default';

class ThemeStore {
    @observable isLightTheme = App.utils.getCookie('theme') !== 'dark';

    constructor() {
        autorun(() => {
            let logoSelector = document.getElementsByClassName('header--primary__img')[0];
            let betSlipImg = document.getElementById('betSlipImg');
            if (!this.isLightTheme) {
                if (logoSelector != null) {
                    logoSelector.attr("src", "/assets/images/logo.svg");
                }
                if (betSlipImg != null) {
                    betSlipImg.attr("src", "/assets/images/slip-empty-state--dark.svg");
                }
            } else {
                if (logoSelector != null) {
                    logoSelector.attr("src", "/assets/images/logo-dark.svg");
                }
                if (betSlipImg != null) {
                    betSlipImg.attr("src", "/assets/images/slip-empty-state.svg");
                }
            }

            this.setTheme();

        })
    }

    @action.bound
    toggleTheme() {
        this.isLightTheme = !this.isLightTheme;
    }

    @action.bound
    setTheme() {
        if (this.isLightTheme) {
            document.body.classList.remove('is-dark');
            document.body.classList.add('is-light');
            document.cookie = "theme=light;path=/";
        }
        else {
            document.body.classList.remove('is-light');
            document.body.classList.add('is-dark');
            document.cookie = "theme=dark;path=/";
        }
    }
}

export default ThemeStore;