import { observable, action } from "mobx";
import {
	TitleStore,
	ValidationStore,
	CurrencyStore,
	UserAuthStore,
	Popupstore,
	AutomaticLogoutStore,
	LiveSessionExpireStore,
	RealityCheckStore,
	SessionExpireStore,
} from "./common";
import {
	Clock,
	TimezoneStore,
	LanguageStore,
	LoginHistoryViewStore,
	ThemeStore,
} from "./components/subheader";
class RootStore {
	@observable isError404PageActive = false;

	// toastr display after account activation
	@observable accountActivationSuccess = false;
	@observable accountActivationInfo = false;
	@observable accountActivationWithEmailActivationInfo = false;
	@observable shouldDisconnect = false;

	@observable isPopupActive = false;
	@observable isLiveSessionExpired = false;
	@observable isSessionExpired = false;
	@observable isRealityCheckPopupActive = false;
	@observable isAutomaticLogoutPopupActive = false;

	constructor() {
		this.titleStore = new TitleStore();
		this.validationStore = new ValidationStore();
		this.currencyStore = new CurrencyStore();

		this.timezonesStore = new TimezoneStore(this);
		this.clock = new Clock(this);
		this.languageStore = new LanguageStore(this);
		this.loginHistoryViewStore = new LoginHistoryViewStore();

		this.userAuthStore = new UserAuthStore(this);
		this.automaticLogoutStore = new AutomaticLogoutStore(this);
		this.liveSessionExpireStore = new LiveSessionExpireStore(this);
		this.realityCheckStore = new RealityCheckStore(this);
		this.sessionExpireStore = new SessionExpireStore(this);

		this.themeStore = new ThemeStore();
	}

	@action.bound
	stopSession() {
		this.sessionExpireStore.clearTimer();
		this.liveSessionExpireStore.clearTimer();
		this.realityCheckStore.clearTimer();
		this.automaticLogoutStore.clearTimer();
		this.sessionExpireStore.deactivatePopup();
		this.liveSessionExpireStore.deactivatePopup();
		this.realityCheckStore.deactivatePopup();
		this.automaticLogoutStore.deactivatePopup();
	}

	@action.bound
	triggerDisconnect() {
		this.shouldDisconnect = true;
	}

	resetDisconnect() {
		this.shouldDisconnect = false;
	}

	@action.bound
	closePopups() {
		this.sessionExpireStore.deactivatePopup();
		this.liveSessionExpireStore.deactivatePopup();
		this.realityCheckStore.deactivatePopup();
		this.automaticLogoutStore.deactivatePopup();
	}

	initClick() {
		this.automaticLogoutStore.onClick();
		this.realityCheckStore.onClick();
		this.sessionExpireStore.onClick();
		this.liveSessionExpireStore.onClick();
	}
}

export default RootStore;
